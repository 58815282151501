import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Loading from "../loading";
import { useLocation } from "react-router-dom";
import makeRequest from "../../libs/request";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { activeAccount, loginFirstTime } from "../../crud/auth.crud";
import { Checkbox } from "antd";
import * as routerHelpers from "../../router/RouterHelpers";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";
import { Link } from "react-router-dom";
import clsx from "clsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
function ActiveAccount(props) {
	let query = useQuery();
	const [isRequested, setRequested] = useState(false);
	const { intl } = props;
	const [loading, setLoading] = React.useState(true);
	const [viewLogin, setViewlogin] = React.useState(false);
	const [show_partner, setShow] = React.useState(true);
	const passRef = React.createRef();
	const rePassRef = React.createRef();
	const strConfirm = props.match.params.strConfirm;
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({
		paddingRight: "2.5rem",
		margin: "0 auto",
		marginRight: "10px",
	});

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	useEffect(() => {
		setTimeout(() => {
			routerHelpers.forgotLastLocation();
			if (strConfirm) {
				let incms;
				if (query.get("incms")) {
					incms = query.get("incms");
				}
				if (incms) {
					makeRequest("post", `auth/guest/activeAccount`, { strConfirm, incms })
						.then(({ data }) => {
							if (data.signal) {
								if (data.data.incms) {
									showSuccessMessageIcon("Active successfuly!");
									setViewlogin(true);
									setLoading(false);
								} else {
									setLoading(false);
								}
							} else {
								return showErrorMessage(data.message);
							}
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					setLoading(false);
				}
			} else {
				showErrorMessage("Opps! Something went wrong!");
			}
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const changeShowPartner = (e) => {
		setShow(e.target.checked);
	};
	if (isRequested) {
		return <Redirect to="/auth" />;
	}
	if (loading) {
		return <Loading />;
	}
	if (viewLogin) {
		return (
			<>
				<div className="kt-login__body" style={{ paddingTop: 145 }}>
					<div className="kt-login__form">
						<div className="kt-login__title">
							<h3>
								<FormattedMessage id="AUTH.LOGIN.TITLE" />
							</h3>
						</div>

						<Formik
							initialValues={{
								email: "",
								password: "",
							}}
							validate={(values) => {
								const errors = {};

								if (!values.email) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.REQUIRED_FIELD",
									});
								} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.INVALID_FIELD",
									});
								}

								if (!values.password) {
									errors.password = intl.formatMessage({
										id: "AUTH.VALIDATION.REQUIRED_FIELD",
									});
								}

								return errors;
							}}
							onSubmit={(values, { setStatus, setSubmitting }) => {
								enableLoading();
								setTimeout(() => {
									loginFirstTime(values.email, values.password, "corEA", show_partner)
										.then(({ data }) => {
											disableLoading();
											if (data.signal) {
												props.login(data.data);
											} else {
												setSubmitting(false);
												showErrorMessage(data.message);
												// setStatus(
												//   intl.formatMessage({
												//     id: "AUTH.VALIDATION.INVALID_LOGIN"
												//   })
												// );
											}
										})
										.catch((error) => {
											disableLoading();
											setSubmitting(false);
											setStatus(
												intl.formatMessage({
													id: "AUTH.VALIDATION.INVALID_LOGIN",
												})
											);
										});
								}, 1000);
							}}
						>
							{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
								<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
									{status ? (
										<div role="alert" className="alert alert-danger">
											<div className="alert-text">{status}</div>
										</div>
									) : (
										<div role="alert" className="alert alert-info">
											<div className="alert-text">
												User email and password to login <strong>COMPETENCY ENTITY</strong>.
											</div>
										</div>
									)}

									<div className="form-group">
										<TextField
											type="email"
											label="Email"
											margin="normal"
											className="kt-width-full"
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
											autoFocus
										/>
									</div>

									<div className="form-group">
										<TextField
											type="password"
											margin="normal"
											label="Password"
											className="kt-width-full"
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password}
											helperText={touched.password && errors.password}
											error={Boolean(touched.password && errors.password)}
										/>
									</div>
									<div className="form-group" style={{ marginTop: 10 }}>
										<Checkbox onChange={changeShowPartner} checked={show_partner}>
											Show at MTC List of Company
										</Checkbox>
									</div>

									<div className="kt-login__actions">
										<Link to="/auth/forgotPassword">Forgot password?</Link>
										<button
											id="kt_login_signin_submit"
											type="submit"
											disabled={isSubmitting}
											className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({
												"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
											})}`}
											style={loadingButtonStyle}
										>
											SIGN IN
										</button>
									</div>
									<div className="kt-login__actions"></div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</>
		);
	}
	return (
		<div
			className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
			style={{ paddingTop: 185 }}
		>
			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>
							<FormattedMessage id="AUTH.REGISTER.SETPASS" />
						</h3>
					</div>

					<Formik
						initialValues={{ password: "", rePassword: "" }}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							if (!values.password) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Please enter password.");
							} else {
								if (values.password.length < 6) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be at least 6 characters.");
								}
								if (values.password.length > 20) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be longer than 20 characters.");
								}
								const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
								if (!regex.test(values.password)) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage(
										"Password contains at least a upper case, lower case, symbol and a number. Except space character"
									);
								}
							}

							if (!values.rePassword) {
								setSubmitting(false);
								rePassRef.current.focus();
								return showErrorMessage("Please confirm password.");
							}

							if (values.password !== values.rePassword) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Invalid. Password and re-password do not match.");
							}

							activeAccount(strConfirm, values.password)
								.then(({ data }) => {
									if (data.signal) {
										showSuccessMessageIcon("Set password success. Please use your account to login system.");
										props.history.push("/auth/login");
									} else {
										setRequested(false);
										return showErrorMessage("Opps! Something was wrong!");
									}
								})
								.catch(() => {
									setSubmitting(false);
									setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.password }));
								});
						}}
					>
						{({ values, status, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit} className="kt-form">
								{status && (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								)}

								<div className="form-group">
									<TextField
										type="password"
										label="Password"
										margin="normal"
										fullWidth={true}
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										autoFocus
										inputRef={passRef}
									/>
								</div>

								<div className="form-group">
									<TextField
										type="password"
										label="Re-password"
										margin="normal"
										fullWidth={true}
										name="rePassword"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.rePassword}
										inputRef={rePassRef}
									/>
								</div>

								<div className="kt-login__actions" style={{ justifyContent: "center" }}>
									{/* <Link to="/auth">
										<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
											Back
										</button>
									</Link> */}

									<button
										type="submit"
										className="btn btn-primary btn-elevate kt-login__btn-primary"
										disabled={isSubmitting}
									>
										Confirm
									</button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(ActiveAccount));
