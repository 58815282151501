import { notification } from "antd"

export const NOTIFICATION_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1
}
export const NOTIFICATION_TYPE = {
  USER_SIGN_UP: 1,
  USER_INVITE: 2,
  MARKER_COMPLETES_MARKING: 3,
  ADMIN_INACTIVE_COURSE: 4,
  ADMIN_INACTIVE_EXTERNAL_COURSE: 5,
}

export function getContentNoti(type, value) {
  let data = {}
  let progress_id
  let course
  let marker
  switch (type) {
    case NOTIFICATION_TYPE.USER_SIGN_UP:
      data.content = `New user ${value?.user?.fullname ?? value?.user?.email} has registered`
      data.path = `/users/edit/${value?.user?.id}`
      break;
    case NOTIFICATION_TYPE.USER_INVITE:
      data.content = `New user ${value?.user?.fullname ?? value?.user?.email} has registered via the invite link`
      data.path = `/users/edit/${value?.user?.id}`
      break;
    case NOTIFICATION_TYPE.MARKER_COMPLETES_MARKING:
      progress_id = value.data.find(item => item.key === "progress_id")
      course = value.data.find(item => item.key === "course")
      marker = value.data.find(item => item.key === "marker")
      data.content = `Marker ${marker.value.fullname ?? ''} completed marking ${value?.user?.fullname ?? value?.user?.email}'s exam in ${course.value.name ?? ''}`
      data.path = `/course/${course.value.id ?? ''}/user-data/${value?.user.id ?? ''}/item/${progress_id.value ?? ''}`
      // data.path = `/course/${course.value.id ?? ''}/user/${value?.user.id ?? ''}`
      break;
    case NOTIFICATION_TYPE.ADMIN_INACTIVE_COURSE:
      course = value.data.find(item => item.key === "course")
      data.content = `The course "${course.value.name ?? ''}" has ended`
      data.path = `/course/edit/${course.value.id ?? ''}`
      break;
    case NOTIFICATION_TYPE.ADMIN_INACTIVE_EXTERNAL_COURSE:
      course = value.data.find(item => item.key === "course")
      data.content = `The course "${course.value.name ?? ''}" has ended`
      data.path = `/course/view/${course.value.id ?? ''}`
      break;
    default:
      data.content = `New user ${value?.user?.fullname ?? value?.user?.email} has registered`
      data.path = `/users/edit/${value?.user?.id}`
  }

  return data;
}

export function checkNotiIsActive(status) {
  switch (status) {
    case NOTIFICATION_STATUS.INACTIVE:
      return false
    case NOTIFICATION_STATUS.ACTIVE:
      return true
    default:
  }
  return false
}

export function checkShowNotification({ isShowNoti, value }) {
  if (isShowNoti) {
    const noti = getContentNoti(value?.type, value)
    notification.info({
      message: '',
      description: noti?.content,
    })
  }
}