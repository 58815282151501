/* eslint-disable no-useless-escape */
export function slugify(str) {
	let slug = "";
	// Change to lower case
	let titleLower = str.toLowerCase();
	// Letter "e"
	slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
	// Letter "a"
	slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
	// Letter "o"
	slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
	// Letter "u"
	slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
	// Letter "i"
	slug = slug.replace(/i|í|ì|ĩ|ỉ|ị/gi, "i");
	// Letter "d"
	slug = slug.replace(/đ/gi, "d");
	// Trim the last whitespace
	slug = slug.replace(/\s*$/g, "");
	// Change whitespace to "-"
	slug = slug.replace(/\s+/g, "-");

	slug = slug.replace(/&/g, "-y-");

	slug = slug.replace(/[^a-z0-9 -]/g, "-");

	slug = slug.replace(/:/gi, "-");
	return slug;
}
export function makeid(length) {
	var result = "";
	var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}
export function validateEmail(email) {
	var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
}
export function validateName(name) {
	var regex = /^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZaàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz0123456789_ ]+$/;

	return regex.test(name);
}
export function validateSpecialCharEmail(email) {
	var regex = /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ.@0123456789_]+$/;

	return regex.test(email.toLowerCase());
}

export function validateSurveyName(survey_name) {
	var regex = /^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZaàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz0123456789_ ]+$/;

	return regex.test(survey_name);
}

export function validateSurveyRounds(title) {
	var regex = /^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZaàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz0123456789_ ]+$/;

	return regex.test(title);
}

export function validateTitleTraining(name) {
	var regex = /^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZaàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz0123456789_)(\- ]+$/;

	return regex.test(name);
}

export function validateBankName(name) {
	var regex = /^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZaàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz_ ]+$/;

	return regex.test(name);
}

export function validateBankNo(no) {
	var regex = /^[0123456789]+$/;

	return regex.test(no);
}

export function validateMobile(mobile) {
	// let regFirst = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	// let regInternational = /^\+(?:[0-9] ?){6,14}[0-9]$/;
	// return regFirst.test(mobile) || regInternational.test(mobile);
	let regexp = /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}/g;
	return regexp.test(mobile);
}

export function validateMaxLength(string, length) {
	if (string.length > length) {
		return string.substring(0, length);
	}
	return false;
}

export function validateMinLength(string, length) {
	if (string.length < length) return true;
	return false;
}

// export function updateMultiForm(dataFromPropsColumn = null, dataFromPropsRow = null, dataColumn = null, dataRow = null) {
// 	let dataUpdateColumn = null;
// 	if (dataFromPropsColumn) {
// 		dataUpdateColumn = Object.entries(dataFromPropsColumn).map((item) => ({
// 			["content"]: item[1].title,
// 			["id"]: item[1].id,
// 		}));
// 	}
// 	let dataUpdateRow = null;
// 	if (dataFromPropsRow) {
// 		dataUpdateRow = Object.entries(dataFromPropsRow).map((item) => ({
// 			["content"]: item[1].title,
// 			["id"]: item[1].id,
// 		}));
// 	}

// 	const updateColumLabel = dataUpdateColumn ? dataUpdateColumn : dataColumn;
// 	const updateRowLabel = dataUpdateRow ? dataUpdateRow : dataRow;
// 	return { updateColumLabel, updateRowLabel };
// }

export function numberToRomanize(num) {
	if (isNaN(num)) return NaN;
	var digits = String(+num).split(""),
		key = [
			"",
			"C",
			"CC",
			"CCC",
			"CD",
			"D",
			"DC",
			"DCC",
			"DCCC",
			"CM",
			"",
			"X",
			"XX",
			"XXX",
			"XL",
			"L",
			"LX",
			"LXX",
			"LXXX",
			"XC",
			"",
			"I",
			"II",
			"III",
			"IV",
			"V",
			"VI",
			"VII",
			"VIII",
			"IX",
		],
		roman = "",
		i = 3;
	while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
}

export function getParameter(name) {
	var url = window.location.href;
	// get query string from url (optional) or window
	var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

	// we'll store the parameters here
	var obj = {};

	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split("#")[0];

		// split our query string into its component parts
		var arr = queryString.split("&");

		for (var i = 0; i < arr.length; i++) {
			// separate the keys and the values
			var a = arr[i].split("=");

			// set parameter name and value (use 'true' if empty)
			var paramName = a[0];
			var paramValue = typeof a[1] === "undefined" ? true : a[1];

			// (optional) keep case consistent
			paramName = paramName.toLowerCase();
			if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

			// if the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName.match(/\[(\d+)?\]$/)) {
				// create key if it doesn't exist
				var key = paramName.replace(/\[(\d+)?\]/, "");
				if (!obj[key]) obj[key] = [];

				// if it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {
					// get the index value and add the entry at the appropriate position
					var index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {
					// otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else {
				// we're dealing with a string
				if (!obj[paramName]) {
					// if it doesn't exist, create property
					obj[paramName] = paramValue;
				} else if (obj[paramName] && typeof obj[paramName] === "string") {
					// if property does exist and it's a string, convert it to an array
					obj[paramName] = [obj[paramName]];
					obj[paramName].push(paramValue);
				} else {
					// otherwise add the property
					obj[paramName].push(paramValue);
				}
			}
		}
	}
	if (obj[name] === undefined) {
		return null;
	}
	return obj[name];
}

export function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

export const getPlanData = (index, id) => {
	const data = JSON.parse(localStorage.getItem("planData"));
	const planData = data ? data[index]?.detail?.filter((x) => x.id === id)[0] : false
	if (planData) {
		return planData?.answer === undefined ? JSON.parse(planData?.detail)[0] === "no" : planData?.answer === "no"
	}
	return true
}

export const getCourseType = () => {
	let courseType = JSON.parse(localStorage.getItem('course-type'));
	if (courseType) {
		return courseType
	}
	return false;
};

