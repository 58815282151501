/* eslint-disable no-lone-blocks */
import React, { Suspense, lazy, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { AUTH_TOKEN_KEY } from "../../config/auth";
import makeRequest from "../../../app/libs/request";
import checkPermission from "../../libs/permission";
{
	/* =============Dashboard============= */
}
const Dashboard = lazy(() => import("../dashboard/Dashboard"));

const Error403 = lazy(() => import("../common/Error403"));
{
	/* ==================================== */
}
const ListCategory = lazy(() => import("../category/ListCategory"));
const ListLevel = lazy(() => import("../level/ListLevel"));
const ListDepartment = lazy(() => import("../department/ListDepartment"));
const CreateDepartment = lazy(() => import("../department/CreateDepartment"));
const EditDepartment = lazy(() => import("../department/EditDepartment"));
const ListTrainingMatrix = lazy(() => import("../department/ListTrainingMatrix"));
const ListCourse = lazy(() => import("../course/ListCourse"));
const ListCourseCom = lazy(() => import("../course/ListCourseCom"));
const Attended = lazy(() => import("../course/Attended"));
const ViewCourse = lazy(() => import("../course/ViewCourse"));
const CreateCourse = lazy(() => import("../course/CreateCourse"));
const EditCourse = lazy(() => import("../course/EditCourse"));
const Analytics = lazy(() => import("../course/Analytics"));
const ListUserSub = lazy(() => import("../users/sub_user/ListUser"));
const PreviewExam = lazy(() => import("../course/preview/PreviewExam"));
// const ListInvite = lazy(() => import("../users/sub_user/ListInvite"));
const CreateUserSub = lazy(() => import("../users/sub_user/CreateUser"));
const EditUserSub = lazy(() => import("../users/sub_user/EditUser"));
const ListMarker = lazy(() => import("../users/marker/ListUser"));
const CreateMarker = lazy(() => import("../users/marker/CreateUser"));
const EditMarker = lazy(() => import("../users/marker/EditUser"));
// const ListOrder = lazy(() => import("../orders/ListOrder"));
// const ViewOrder = lazy(() => import("../orders/ViewOrder"));
const ListInquiry = lazy(() => import("../inquiry/ListInquiry"));
const ViewInquiry = lazy(() => import("../inquiry/ViewInquiry"));
const ListAdmin = lazy(() => import("../admin/ListUser"));
const CreateAdmin = lazy(() => import("../admin/CreateUser"));
const EditAdmin = lazy(() => import("../admin/EditUser"));
const ListTranning = lazy(() => import("../trannings/ListTranning"));
const CreateTranning = lazy(() => import("../trannings/CreateTranning"));
const EditTranning = lazy(() => import("../trannings/EditTranning"));
const ListExam = lazy(() => import("../quiz/ListExam"));
const CreateExam = lazy(() => import("../quiz/CreateExam"));
const EditExam = lazy(() => import("../quiz/EditExam"));
const SubcriptionPlan = lazy(() => import("../home/SubcriptionPlan"));
const Payment = lazy(() => import("../payment/PaymentInfo"));
const UpdatePlan = lazy(() => import("../payment/UpdatePlan"));
const BillingList = lazy(() => import("../payment/BillingList"));
const UpdateCard = lazy(() => import("../payment/UpdateCard"));
const StorageDetail = lazy(() => import("../payment/StorageDetail"));
const MyCompany = lazy(() => import("../home/MyCompany"));
const Appearance = lazy(() => import("../website/Appearance"));
const Policy = lazy(() => import("../static-page/Policy"));
const Condition = lazy(() => import("../static-page/Condition"));
// const CompanyProfile = lazy(() => import("../website/CompanyProfile"));
const DashboardMarker = lazy(() => import("../dashboard/DashboardMarker"));
const DetailProgress = lazy(() => import("../dashboard/DetailProgress"));
const PreviewQuiz = lazy(() => import("../quiz/Preview"));
const ExamResponse = lazy(() => import("../course/response/Exam"));
const DetailResponse = lazy(() => import("../course/response/Detail"));
const ListRole = lazy(() => import("../roles/ListRole"));
const EditRole = lazy(() => import("../roles/EditRole"));
const CreateRole = lazy(() => import("../roles/CreateRole"));

const CreateBuilder = lazy(() => import("../course-builder/CreateBuilder"));
const ListBuilder = lazy(() => import("../course-builder/ListBuilder"));
const UpdateBuilder = lazy(() => import("../course-builder/UpdateBuilder"));
const PreviewBuilder = lazy(() => import("../course-builder/PreviewBuilder"));

export default function HomePage() {
	const [isAdmin, setIsAdmin] = useState(true);
	const getCountStatusNew = (corporate_id) => {
		makeRequest("get", `inquiry/countStatusNew`, { corporate_id })
			.then(({ data }) => {
				if (data.signal) {
					let list = document.getElementsByClassName("inquiryStatusNew");
					let listNoti = document.getElementsByClassName("inquiry-noti-number");
					if (listNoti[0]) {
						listNoti[0].innerHTML = data.data;
						if (data.data === 0) {
							listNoti[0].remove();
						}
					}
					if (list[0]) {
						list[0].innerHTML = data.data;
						if (data.data === 0) {
							list[0].remove();
						}
					}
					if (data.data > 0 && !listNoti[0]) {
						const divInquiryNoti = document.querySelector('.link-inquiry .notification-icon-2');
						if (divInquiryNoti && data.data > 0) {
							divInquiryNoti.innerHTML += `<div class="border-radius-99 noti-number inquiry-noti-number" >${data.data}</div>`;
						}
					}
					if (data.data > 0 && !list[0]) {
						const divInquiry = document.querySelector("[href='/inquiry/list']:not(.link-inquiry)");
						if (divInquiry && data.data > 0)
							divInquiry.innerHTML += `<span class="kt-menu__link-badge"><span class="kt-badge kt-badge--md kt-badge--rounded align-items-center inquiryStatusNew">${data.data}</span></span>`;
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// const [role, setRole] = useState("");
	setTimeout(() => {
		if (localStorage.getItem(AUTH_TOKEN_KEY)) {
			let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
			if (auth && auth.authToken) {
				const token = JSON.parse(auth.authToken);
				makeRequest("get", `user/userInfo?token=${token}`)
					.then(({ data }) => {
						if (data.signal) {
							setIsAdmin(data.data.is_manager);
							// setRole(data.data.role_admin);
							if (data.data?.corporate_id && checkPermission('inquiry.view')) {
								getCountStatusNew(data.data.corporate_id);
							}
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}, 500);
	if (isAdmin) {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					{
						/* Redirect from root URL to /dashboard. */
						<Redirect exact from="/" to="/dashboard" />
					}
					{/* Route other */}
					<Route path="/subscription-plan" component={SubcriptionPlan} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/my-company" component={MyCompany} />
					{/* <Redirect to="Error403" /> */}
					<Route path="/Error403" component={Error403} />
					{/* <Redirect to="TestMe" /> */}
					<Route path="/industry/list" component={ListCategory} />
					<Route path="/level/list" component={ListLevel} />
					<Route path="/courses/list" component={ListCourse} />
					<Route path="/courses-com/list" component={ListCourseCom} />
					<Route path="/course/create" component={CreateCourse} />
					<Route path="/course/edit/:id" component={EditCourse} />
					<Route path="/course/view/:id" component={ViewCourse} />
					<Route path="/course/analytics" component={Analytics} />
					<Route path="/course/attendeed/:id" component={Attended} />
					{/* <Route path="/order/list" component={ListOrder} />
				<Route path="/order/view/:id" component={ViewOrder} /> */}
					<Route path="/inquiry/list" component={ListInquiry} />
					<Route path="/inquiry/view/:id" component={ViewInquiry} />
					<Route path="/training/list" component={ListTranning} />
					<Route path="/training/create" component={CreateTranning} />
					<Route path="/training/edit/:id" component={EditTranning} />

					<Route path="/users/list" component={ListUserSub} />
					{/* <Route path="/invite/list" component={ListInvite} /> */}
					<Route path="/users/create" component={CreateUserSub} />
					<Route path="/users/edit/:id" component={EditUserSub} />
					<Route path="/department/list" component={ListDepartment} />
					<Route path="/training-matrix/create" component={CreateDepartment} />
					<Route path="/training-matrix/edit/:id" component={EditDepartment} />
					<Route path="/training-matrix/list" component={ListTrainingMatrix} />
					<Route path="/admin/list" component={ListAdmin} />
					<Route path="/admin/create" component={CreateAdmin} />
					<Route path="/admin/edit/:id" component={EditAdmin} />
					<Route path="/quiz/list" component={ListExam} />
					<Route path="/quiz/create" component={CreateExam} />
					<Route path="/quiz/edit/:id" component={EditExam} />
					<Route path="/payment" component={Payment} />
					<Route path="/change-subscription-plan" component={UpdatePlan} />
					<Route path="/billing-activity" component={BillingList} />
					<Route path="/change-payment-method" component={UpdateCard} />
					<Route path="/storage-detail" component={StorageDetail} />
					<Route path="/appearance" component={Appearance} />
					<Route path="/policy" component={Policy} />
					<Route path="/condition" component={Condition} />
					<Route path="/marker/list" component={ListMarker} />
					<Route path="/marker/create" component={CreateMarker} />
					<Route path="/marker/edit/:id" component={EditMarker} />
					<Route path="/course/:id/preview-exam/:exam_id/version/:version_id" component={PreviewExam} />
					<Route path="/quiz/preview/:id" component={PreviewQuiz} />
					<Route path="/course/:id/user/:user_id" component={ExamResponse} />
					<Route path="/course/:id/user-data/:user_id/item/:progress_id" component={DetailResponse} />
					{/* <Route path="/company-profile" component={CompanyProfile} /> */}
					<Route path="/role/list" component={ListRole} />
					<Route path="/role/edit/:id" component={EditRole} />
					<Route path="/role/create" component={CreateRole} />
					<Route path="/course-builder/list" component={ListBuilder} />
					<Route path="/course-builder/create" component={CreateBuilder} />
					<Route path="/course-builder/edit/:id" component={UpdateBuilder} />
					<Route path="/course-builder/preview/:id" component={PreviewBuilder} />
				</Switch>
			</Suspense>
		);
	} else
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					{
						/* Redirect from root URL to /dashboard. */
						<Redirect exact from="/" to="/dashboard" />
					}
					{/* Route other */}
					<Route path="/dashboard" component={DashboardMarker} />
					{/* <Redirect to="Error403" /> */}
					<Route path="/Error403" component={Error403} />
					<Route path="/detail-progress/:id" component={DetailProgress} />
				</Switch>
			</Suspense>
		);
}
