import checkPermission from "../../app/libs/permission";
import { getCourseType } from "../../app/libs/utils";

export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: 'Review Test',
        root: true,
        image: '/images/menu-icon/icon-19.svg',
        page: 'dashboard',
        permission: 'marker',
        pathname: [
          {
            content: 'Detail',
            path: '/detail-progress/:id',
          },
        ],
      },
      {
        title: 'Dashboard',
        root: true,
        page: 'dashboard',
        translate: 'MENU.DASHBOARD',
        permission: 'dashboard.view',
        bullet: 'dot',
        image: '/images/menu-icon/icon-18.svg',
        pathname: [
          {
            content: 'Dashboard',
            path: '/dashboard',
          },
          {
            content: 'Inquiry Management',
            path: '/inquiry/list',
          },
          {
            content: 'Inquiry Detail',
            path: '/inquiry/view/:id',
          },
        ],
      },
      {
        title: 'Course Management',
        root: true,
        image: '/images/menu-icon/icon-16.svg',
        bullet: 'dot',
        permission: [
          'course',
          'course_assigned.view',
          'ce_course.view',
          'course_analytics.view',
        ],
        submenu: [
          {
            title: 'External Courses',
            page: 'courses/list',
            permission: ['course_assigned.view'],
            pathname: [
              {
                content: 'External Courses',
                path: '/courses/list',
              },
              {
                content: 'External Courses',
                path: '/course/view/:id',
                breadcrumb: [
                  {
                    title: 'External Courses',
                    link: '/courses/list',
                  },
                  {
                    title: 'View Course',
                    link: '',
                  },
                ],
              },
              {
                content: 'Attendee',
                path: '/course/attendeed/:id',
              },
              {
                content: 'Review Exam',
                path: '/course/:course/preview-exam/:exam/version/:version'
              },
              {
                content: 'Detail Response Exam',
                path: '/course/:id/user-data/:user_id/item/:progress_id'
              },
              {
                content: 'Response Exam',
                path: '/course/:id/user/:user_id'
              },
            ],
          },
          {
            title: 'Your Courses',
            page: 'courses-com/list',
            permission: ['ce_course.view'],
            pathname: [
              {
                content: 'Your Courses',
                path: '/courses-com/list',
              },
              {
                content: 'Create Course',
                path: '/course/create',
              },
              {
                content: checkPermission('ce_course.update') ? `Edit Course` : `Detail Course`,
                path: '/course/edit/:id',
              },
            ],
          },
          {
            title: 'Course Analytics',
            page: 'course/analytics',
            permission: 'course_analytics.view',
            pathname: [
              {
                content: 'Course Analytics',
                path: '/course/analytics',
              }
            ],
          },
        ],
      },
      {
        title: 'Course Builder',
        root: true,
        page: 'course-builder/list',
        image: '/images/menu-icon/icon-14.svg',
        permission: 'course_builder.view',
        pathname: [
          {
            content: 'Course Builder',
            path: '/course-builder/list',
          },
          {
            content: 'Course Builder',
            path: '/course-builder/create',
          },
          {
            content: 'Course Builder',
            path: '/course-builder/edit/:id',
          },
          {
            content: 'Preview',
            path: '/course-builder/preview/:id',
          },
        ],
      },
      {
        title: 'Quiz Management',
        root: true,
        image: '/images/menu-icon/icon-13.svg',
        page: 'quiz/list',
        pathname: [
          {
            content: 'Quiz Management',
            path: '/quiz/list',
          },
          {
            content: 'Review Quiz',
            path: '/quiz/preview/:id',
          },
          {
            content: 'Create Quiz',
            path: '/quiz/create',
          },
          {
            content: 'Edit Quiz',
            path: '/quiz/edit/:id',
          },
        ],
        permission: 'exam.view',
      },
      {
        title: 'Training Calendar',
        root: true,
        image: '/images/menu-icon/icon-12.svg',
        page: 'training/list',
        bullet: 'dot',
        permission: 'training.view',
        pathname: [
          {
            content: 'Training Calendar',
            path: '/training/list',
          },
          {
            content: 'Create Event',
            path: '/training/create',
          },
          {
            content: checkPermission('training.update') ? `Edit Event` : `Detail Event`,
            path: '/training/edit/:id',
          }
        ],
      },
      // {
      //   title: 'Inquiry Management',
      //   root: true,
      //   image: '/images/menu-icon/icon-5.svg',
      //   page: 'inquiry/list',
      //   badge: {
      //     type: 'kt-badge--md kt-badge--rounded align-items-center inquiryStatusNew',
      //     value: 0,
      //   },
      //   permission: 'inquiry.view',
      //   pathname: [
      //     {
      //       content: 'Inquiry Management',
      //       path: '/inquiry/list',
      //     },
      //     {
      //       content: 'Inquiry Detail',
      //       path: '/inquiry/view/:id',
      //     },
      //   ],
      // },
      {
        title: 'Setting',
        root: true,
        image: '/images/menu-icon/icon-1.svg',
        bullet: 'dot',
        permission: [
          'department.view',
          'user_mngt',
          'admin.view',
          'user.view',
          'marker.view',
          'role.view',
          'payment.view',
          'website.view',
          'property.view',
          'industry.view',
          'level.view',
        ],
        submenu: [
          {
            title: 'LMS Appearance Configuration',
            bullet: 'dot',
            permission: 'website.view',
            page: 'appearance',
            pathname: [
              {
                content: 'LMS Appearance Configuration',
                path: '/appearance',
              },
            ],
          },
          {
            title: 'Course Categories',
            bullet: 'dot',
            permission: ['property.view', 'industry.view', 'level.view'],
            submenu: [
              {
                title: 'Industry',
                page: 'industry/list',
                permission: 'industry.view',
                pathname: [
                  {
                    content: 'Industry',
                    path: '/industry/list',
                  },
                ],
              },
              {
                title: 'Level',
                page: 'level/list',
                permission: 'level.view',
                pathname: [
                  {
                    content: 'Level',
                    path: '/level/list',
                  },
                ],
              },
            ],
          },
          // {
          //   title: 'Department Management',
          //   page: 'department/list',
          //   permission: 'department.view',
          //   pathname: [
          //     {
          //       content: 'Department management',
          //       path: '/department/list',
          //     },
          //   ],
          // },
          {
            title: 'Department & Training Matrix',
            page: 'training-matrix/list',
            permission: 'department.view',
            pathname: [
              {
                content: 'Training Matrix',
                path: '/training-matrix/list',
              },
              {
                content: 'Create Department',
                path: '/training-matrix/create',
              },
              {
                content: 'Edit Department',
                path: '/training-matrix/edit/:id',
              },
            ],
          },
          {
            title: 'User Management',
            bullet: 'dot',
            permission: [
              'user_mngt',
              'admin.view',
              'user.view',
              'marker.view',
              'role.view',
            ],
            submenu: [
              {
                title: 'Admin',
                page: 'admin/list',
                permission: 'admin.view',
                pathname: [
                  {
                    content: 'Admin Management',
                    path: '/admin/list',
                  },
                  {
                    content: 'Create Admin',
                    path: '/admin/create',
                  },
                  {
                    content: checkPermission('admin.update') ? `Edit Admin` : `View Admin`,
                    path: '/admin/edit/:id',
                  },
                ],
              },
              {
                title: 'Users',
                page: 'users/list',
                permission: 'user.view',
                pathname: [
                  {
                    content: 'User Management',
                    path: '/users/list',
                  },
                  {
                    content: 'Create User',
                    path: '/users/create',
                  },
                  {
                    content: checkPermission('user.update') ? `Edit User` : `View User`,
                    path: '/users/edit/:id',
                  },
                ],
              },
              {
                title: 'Role & Permission',
                page: 'role/list',
                permission: 'role.view',
                pathname: [
                  {
                    content: 'Role & Permissions Management',
                    path: '/role/list',
                  },
                  {
                    content: 'Create Role',
                    path: '/role/create',
                  },
                  {
                    content: 'Edit Role',
                    path: '/role/edit/:id',
                  },
                ],
              },
              {
                title: 'Markers',
                page: 'marker/list',
                permission: 'marker.view',
                pathname: [
                  {
                    content: 'Marker Management',
                    path: '/marker/list',
                  },
                  {
                    content: 'Invite Marker',
                    path: '/marker/create',
                  },
                  {
                    content: !checkPermission("marker.view") ? `Detail Marker` : `Edit Marker`,
                    path: '/marker/edit/:id',
                  },
                ],
              },
            ],
          },
          {
            title: 'Payment',
            page: 'payment',
            permission: 'payment.view',
            pathname: [
              {
                content: 'Payment Information',
                path: '/payment',
              },
              {
                content: 'Update Subscription Plan',
                path: '/change-subscription-plan',
              },
              {
                content: 'Update Payment method',
                path: '/change-payment-method',
              },
              {
                content: 'Billing Activity',
                path: '/billing-activity',
              },
              {
                content: 'Storage Details',
                path: '/storage-detail',
              },
              {
                content: 'Choose your Subscription Plan',
                path: '/subscription-plan',
              },
            ]
          },
        ],
      },
      {
        title: 'Terms and Conditions',
        root: true,
        image: '/images/menu-icon/icon-19.svg',
        bullet: 'dot',
        permission: 'page.view',
        submenu: [
          {
            title: 'Privacy policy',
            page: 'policy',
            permission: 'page.view',
            pathname: [
              {
                content: 'Privacy policy',
                path: '/policy',
              },
            ]
          },
          {
            title: 'Terms and Conditions',
            page: 'condition',
            permission: 'page.view',
            pathname: [
              {
                content: 'Terms and Conditions',
                path: '/condition',
              },
            ]
          },
        ],
      },
    ],
  },
}
