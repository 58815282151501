import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class HeaderBreadcrumb extends Component {
  render() {
    let { breadcrumb } = this.props
    let numberBreadcrumb = breadcrumb.length
    return (
      <div
        className={`kt-header-breadcrumb w-100`}
        id='kt-header-breadcrumb'
      >
        <div className='page-header page-header-default w-100'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb bg-color-white pl-20'>
              <li className='breadcrumb-item f-medium_20'>
                <Link to='/dashboard'>Dashboard</Link>
              </li>
              {breadcrumb.map((it, idx) => {
                if (idx + 1 === numberBreadcrumb) {
                  return (
                    <li
                      className='breadcrumb-item active f-medium_20'
                      content='. '
                      key={idx}
                      aria-current='page'
                    >
                      {it.title}
                    </li>
                  )
                } else {
                  return (
                    <li key={idx} className='breadcrumb-item f-medium_20' content='. '>
                      <Link to={it.link}>{it.title}</Link>
                    </li>
                  )
                }
              })}
            </ol>
          </nav>
        </div>
      </div>
    )
  }
}

export default HeaderBreadcrumb
